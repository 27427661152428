<!--
 * @Descripttion: 查看项目信息
 * @version: 1.0
 * @Author: Niklaus
 * @Date: 2021-08-28 17:31:50
-->
<template>
<el-container>
  <el-main>
    <el-row type="flex" align="middle">
      <h1 class="base-title">{{baseInfo.projectName}}</h1>
      <p class="desc">项目类型</p>
       <el-tag type="primary" size="normal" >{{baseInfo.typeName}}</el-tag>
    </el-row>
    <p class="mid-title">基本信息</p>
    <el-row type="flex" align="middle">
      <el-col :span="3"><span class="bw">项目编号</span></el-col>
      <el-col :span="6"><span class="desc">{{baseInfo.projectNo}}</span></el-col>
      <el-col :span="3"><span class="bw">报价方式</span></el-col>
      <el-col :span="6" ><el-tag type="success" size="normal">{{ baseInfo.quotationMethod | purchaseType }}</el-tag></el-col>
    </el-row>
    <el-row type="flex" align="middle" class="mt-normal">
      <el-col :span="3"><span class="bw">竞价轮次</span></el-col>
      <el-col :span="6"><span class="desc">{{baseInfo.biddRounds}}</span></el-col>
    </el-row>
    <el-row class="mt" :gutter="20" v-if="rounds.length>0">
      <el-col :span="8" v-for="(item,index) in rounds" :key="item.id">
        <p class="mini-title">第{{index + 1}}轮竞价</p>
        <el-row>
          <el-col :span="8" class="bw mt-normal">竞价开始时间</el-col>
          <el-col :span="16" class="info mt-normal">{{item.rq1}}</el-col>
           </el-row>
           <el-row>
          <el-col :span="8" class="bw mt-normal">竞价结束时间</el-col>
          <el-col :span="16" class="info mt-normal">{{item.rq2}}</el-col>
        </el-row>
      </el-col>
    </el-row>

    <p class="mid-title mt">联系方式</p>
    <el-row  class="mt-normal" :gutter="20">
      <el-col :span="3"><span class="bw mt-normal">采购单位</span></el-col>
      <el-col :span="7"><span class="info mt-normal">{{baseInfo.bnStoreName}}</span></el-col>
      <el-col :span="3"><span class="bw mt-normal">联系电话</span></el-col>
      <el-col :span="7"><span class="info mt-normal">{{baseInfo.linkPhone}}</span></el-col>
    </el-row>
    <el-row type="flex" align="middle" :gutter="20" class="mt-normal">
      <el-col :span="3"><span class="bw mt-normal">联系人</span></el-col>
      <el-col :span="7"><span class="info mt-normal">{{baseInfo.linkMan}}</span></el-col>
      <el-col :span="3"><span class="bw mt-normal">联系地址</span></el-col>
      <el-col :span="7"><span class="info mt-normal">{{baseInfo.linkAddr}}</span></el-col>
    </el-row>

    <p class="mid-title mt">竞价需求</p>
    <el-row :gutter="20" class="mt" v-if="baseInfo.typeClassCode == 'A'">
      <el-col :span="3"><span class="bw">交货期</span></el-col>
      <el-col :span="7"><span class="info">{{baseInfo.deliveryTerm}}</span></el-col>
      <el-col :span="3"><span class="bw">质保期</span></el-col>
      <el-col :span="7"><span class="info">{{baseInfo.qaTerm}}</span></el-col>
    </el-row>

    <el-row :gutter="20" class="mt" v-else-if="baseInfo.typeClassCode == 'B'">
      <el-col :span="3"><span class="bw">计划工期</span></el-col>
      <el-col :span="7"><span class="info">{{baseInfo.planTerm}}</span></el-col>
      <el-col :span="3"><span class="bw">质量目标</span></el-col>
      <el-col :span="7"><span class="info">{{baseInfo.qualityTarget}}</span></el-col>
    </el-row>

    <el-row :gutter="20" class="mt" v-else-if="baseInfo.typeClassCode == 'C'">
      <el-col :span="3"><span class="bw">服务时间</span></el-col>
      <el-col :span="7"><span class="info">{{baseInfo.serviceTerm}}</span></el-col>
    </el-row>
     <el-row :gutter="20" class="mt-normal">
      <el-col :span="3"><span class="bw">其他要求</span></el-col>
      <el-col :span="7"><span class="info">{{baseInfo.otherItem}}</span></el-col>
    </el-row>

    <p class="mid-title mt">承诺书</p>
    <el-row>
      <el-col :span="24">
        <ul class="el-upload-list el-upload-list--text">
          <li
            :key="index"
            v-for="(file,index) in fileList"
            class="el-upload-list__item tempLi">
            <p class="el-upload-list__item-name">
              <a href="#" @click="handleView(file.commUrl)">
              {{file.commName}}
              </a>
            </p>
          </li>
        </ul>
      </el-col>
    </el-row>
	<p class="mid-title mt">附件</p>
	<el-row>
    <el-col :span="24">
      <ul class="el-upload-list el-upload-list--text">
        <li
        :key="index"
        v-for="(item,index) in attachment"
        class="el-upload-list__item tempLi">
          <p class="el-upload-list__item-name">
            <a href="#" @click="downloadAttachment(item.id)">
              {{item.attachmenttitle}}.{{item.extend}}
            </a>
          </p>
          <label class="el-upload-list__item-status-label">
            <i class="el-icon-upload-success el-icon-circle-check"></i>
          </label>
        </li>
      </ul>
    </el-col>
	</el-row>
    <p class="mid-title mt">网盘链接</p>
    <el-row>
      <el-col :span="24">
        <ul class="el-upload-list el-upload-list--text">
          <li
            :key="index"
            v-for="(net,index) in netDisk"
            class="el-upload-list__item tempLi">
            <p class="el-upload-list__item-name">
              名称：{{net.ndName}}
            </p>
            <p class="el-upload-list__item-name">
              网址：{{net.ndContent}}
            </p>
            <p class="el-upload-list__item-name">提取码：{{net.ndCode}}</p>
            <label class="el-upload-list__item-status-label">
              <i class="el-icon-upload-success el-icon-circle-check"></i>
            </label>
          </li>
        </ul>
      </el-col>
    </el-row>
    <p class="mt mid-title">需求条目</p>
    <el-table :data="items" header-cell-class-name="header-style" class="mt">
      <el-table-column v-for="col in columns"
        :prop="col.key"
        :key="col.id"
        :label="col.name"
        :min-width="col.width">
      </el-table-column>
    </el-table>
  </el-main>
  <el-footer class="text-center mt">
    <el-button type="primary"  @click="$router.back(-1)">返回</el-button>
  </el-footer>
  </el-container>


</template>

<script>
import {httpPost,httpGet,httpPut} from '@/utils/http'
import global from '@/common/global';
export default {
  name: 'Project',
  data() {
    return {
		//项目基本信息
		baseInfo:{
			projectName:"",				//项目名称
			typeName:"",				//项目类型
			projectNo:"",				//项目编号
			quotationMethod:"",			//报价方式:1金额报价;2优惠率报价
			bnStoreName:"",				//采购单位
			linkPhone:"",				//联系电话
			linkMan:"",					//联系人
			linkAddr:"",				//联系地址
			planTerm:"",				//计划工期
			qualityTarget:"",			//质量目标
			otherItem:"",				//其它要求
			biddRounds:"",				//轮次
		},
		//附件
		attachment:[],
		//轮次
		rounds:[],
		//承诺书
		fileList: [],
		//网盘
		netDisk: [],
		//项目
		columns:[],
    //金额报价-行要素
		columns1:[
			{id: '0',name:'标的分类', width:'100px', key: 'secondTypeName' },
			{id: '1',name:'标的名称', width:'200px', key: 'itemName' },
			{id: '2',name:'规格型号', width:'200px', key: 'standards' },
			{id: '3',name:'详细参数', width:'200px', key: 'detailParam' },
			{id: '4',name:'采购数量', width:'80px', key: 'purchaseQuantity' },
			{id: '5',name:'计量单位', width:'80px', key: 'uom' },
			{id: '6',name:'预算金额（元）', width:'140px', key: 'itemBudget' },
			{id: '10',name:'其他说明', width:'', key: 'otherDesc' },
		],
     //优惠率报价-行要素
		columns2:[
			{id: '0',name:'标的分类', width:'100px', key: 'secondTypeName' },
			{id: '1',name:'标的名称', width:'200px', key: 'itemName' },
			{id: '2',name:'规格型号', width:'200px', key: 'standards' },
			{id: '3',name:'详细参数', width:'200px', key: 'detailParam' },
			{id: '4',name:'采购数量', width:'80px', key: 'purchaseQuantity' },
			{id: '5',name:'计量单位', width:'80px', key: 'uom' },
			{id: '6',name:'预算优惠率（%）', width:'140px', key: 'itemBudget' },
			{id: '7',name:'预算暂定价（元）', width:'140px', key: 'budgetTempPrice' },
			// {id: '8',name:'人工费（元）', width:'140px', key: 'laborCost' },
			// {id: '9',name:'安全文明施工费（元）', width:'160px', key: 'safeCivilizeCost' },
			{id: '10',name:'其他说明', width:'', key: 'otherDesc' },
		],
    //金额报价定制版-行要素
    columns3:[
			{id: '0',name:'标的分类', width:'100px', key: 'secondTypeName' },
			{id: '1',name:'标的名称', width:'200px', key: 'itemName' },
			{id: '2',name:'规格型号', width:'200px', key: 'standards' },
			{id: '3',name:'详细参数', width:'200px', key: 'detailParam' },
			{id: '4',name:'采购数量', width:'80px', key: 'purchaseQuantity' },
			{id: '5',name:'计量单位', width:'80px', key: 'uom' },
			{id: '6',name:'预算控制价（元）', width:'140px', key: 'itemBudget' },
			{id: '7',name:'最高限价（元）', width:'140px', key: 'ceilingPrice' },
			// {id: '8',name:'人工费（元）', width:'140px', key: 'laborCost' },
			// {id: '9',name:'安全文明施工费（元）', width:'160px', key: 'safeCivilizeCost' },
			{id: '10',name:'其他说明', width:'', key: 'otherDesc' },
		],

    columns4:[
			{id: '0',name:'标的分类', width:'100px', key: 'secondTypeName' },
			{id: '1',name:'标的名称', width:'200px', key: 'itemName' },
			{id: '2',name:'规格型号', width:'200px', key: 'standards' },
			{id: '3',name:'详细参数', width:'200px', key: 'detailParam' },
			{id: '4',name:'采购数量', width:'80px', key: 'purchaseQuantity' },
			{id: '5',name:'计量单位', width:'80px', key: 'uom' },
			{id: '6',name:'预算金额（元）', width:'140px', key: 'itemBudget' },
			{id: '7',name:'最低加价幅度（元）', width:'140px', key: 'miniPriceIncreRange' },
			{id: '10',name:'其他说明', width:'', key: 'otherDesc' },
		],
		items:[]
    }
  },
	mounted() {
		//项目id
		let projectId = this.$route.query.projectId
		if(projectId){
			//查询项目信息
			this.queryProject(projectId)
		}
	},
	methods:{
		//查询项目
		queryProject:function(id){
			let that = this
			let url = global.gateway + "/ewbid/bidd/tbEwProjectBaseInfo/getProjectWithId"
			let params = {
				"projectId":id
			}
			httpGet(url,params).then(res=>{
				if(res.status=="200"&&res.data.retCode=="0"){
					//基本信息
					if(res.data.baseInfo){
						that.baseInfo = res.data.baseInfo
            if(that.baseInfo.quotationMethod == '1') {
              that.columns = that.columns1;
            }else if(that.baseInfo.quotationMethod == '2'){
              that.columns = that.columns2;
            }else if(that.baseInfo.quotationMethod == '3'){
              that.columns = that.columns3;
            }else if(that.baseInfo.quotationMethod == '4'){
              that.columns = that.columns4;
            }else{
              that.columns = that.columns1;
            }
					}
					//承诺书
					if(res.data.commLetter){
						that.fileList = res.data.commLetter
					}
					//附件
					if(res.data.attachment){
						that.attachment = res.data.attachment
					}
					//网盘
					if(res.data.netDisk){
						that.netDisk = res.data.netDisk
					}
					//轮次
					if(res.data.rounds){
						that.rounds = res.data.rounds
						for(let ii=0;ii<that.rounds.length;ii++){
							//formatDateStr
							that.rounds[ii].rq1 = (that.rounds[ii].biddBeginDatetime)?global.formatDateStr((new Date(that.rounds[ii].biddBeginDatetime)),'yyyy-MM-dd hh:mm:ss'):""
							that.rounds[ii].rq2 = (that.rounds[ii].biddEndDatetime)?global.formatDateStr((new Date(that.rounds[ii].biddEndDatetime)),'yyyy-MM-dd hh:mm:ss'):""
						}
					}
					//项目需求
					if(res.data.items){
						that.items = res.data.items
					}
				}
			}).catch(err=>{
        console.log(err)
				// alert(JSON.stringify(err))
			})
		},
		//下载附件
		downloadAttachment:function(id){
			window.location.href = global.gateway + "/ewbid/bidd/tbEwProjectBaseInfo/downloadAttachment?id="+id
		},
		//下载承诺书
		downloadCommletter:function(id){
			alert(id)
		},
    //查看链接
    handleView(url){
      if(url){
        window.open(url);
      }
    },
	}
}
</script>

<style lang="scss" scoped>
  .el-main {
    .el-row--flex {
      .desc {
        margin: 0 15px;
        color: #808080;
      }
    }
    .info {
      color: #737373;
      & + .bw {
        margin-top: 10px;
      }
    }

  }
</style>
